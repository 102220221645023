import { useState } from 'react'

export function useFeatureOpportunityActionModal() {
  const [selectedFeaturedOpportunity, setSelectedFeaturedOpportunity] = useState<
    { id?: string; name?: string; featuredItemId?: string } | undefined
  >(undefined)
  const [isFeaturedOpportunityModalOpen, setIsFeaturedOpportunityModalOpen] = useState<boolean>(false)
  const [isRemoveFeaturedOpportunityModalOpen, setIsRemoveFeaturedOpportunityModalOpen] = useState<boolean>(false)

  const openFeaturedOpportunityModal = ({ id, name }) => {
    setSelectedFeaturedOpportunity({ id, name })
    setIsFeaturedOpportunityModalOpen(true)
  }

  const closeFeaturedOpportunityModal = () => {
    setIsFeaturedOpportunityModalOpen(false)
  }

  const openRemoveFeaturedOpportunityModal = ({ featuredItemId, name }) => {
    setSelectedFeaturedOpportunity({ featuredItemId, name })
    setIsRemoveFeaturedOpportunityModalOpen(true)
  }

  const closeRemoveFeaturedOpportunityModal = () => {
    setIsRemoveFeaturedOpportunityModalOpen(false)
  }

  return {
    selectedFeaturedOpportunity,
    isFeaturedOpportunityModalOpen,
    openFeaturedOpportunityModal,
    closeFeaturedOpportunityModal,
    isRemoveFeaturedOpportunityModalOpen,
    openRemoveFeaturedOpportunityModal,
    closeRemoveFeaturedOpportunityModal
  }
}
