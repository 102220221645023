import { useTranslation } from 'react-i18next'
import { ScimApiKeysTableProps } from './ScimApiKeysTable.types'
import {
  Badge,
  ButtonText,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableProvider,
  TableRow
} from '@percent/lemonade'
import format from 'date-fns/format'
import { useState } from 'react'
import { RevokeScimApiKeyModal } from './modals/RevokeScimApiKeyModal'
import styles from './ScimApiKeysTable.module.scss'

enum RevokeKeyModalState {
  OPERATION_CONFIRMATION = 'Revoke key confirmation',
  STATUS = 'Operation status'
}

export function ScimApiKeysListTable({
  data,
  isLoading,
  previousPage,
  nextPage,
  refresh,
  canManageApiKeys
}: Readonly<ScimApiKeysTableProps>) {
  const { t } = useTranslation()
  const [isOpenRevokeScimApiKeyModal, setIsOpenRevokeScimApiKeyModal] = useState(false)
  const [selectedApiKey, setSelectedApiKey] = useState<{ id: string; name: string } | null>(null)
  const [modalState, setModalState] = useState(RevokeKeyModalState.OPERATION_CONFIRMATION)

  const columnsKeyManagement = [
    { id: t('table.header.tokenName'), isSortable: false },
    { id: t('table.header.tokenValue'), isSortable: false },
    { id: t('table.header.date'), isSortable: false },
    { id: t('table.header.actions'), isSortable: false }
  ]

  const keysNumber = data?.length || 0

  const handleOpenModal = (id: string, name: string) => {
    setSelectedApiKey({ id, name })
    setIsOpenRevokeScimApiKeyModal(true)
    setModalState(RevokeKeyModalState.OPERATION_CONFIRMATION)
  }

  const handleCloseModal = () => {
    setIsOpenRevokeScimApiKeyModal(false)
    setSelectedApiKey(null)
  }

  const formatSecretKey = (secretKey: string | undefined) => {
    if (!secretKey) return ''
    return `${secretKey.slice(0, 12)}${'*'.repeat(12)}`
  }

  return (
    <>
      <TableProvider
        columns={columnsKeyManagement}
        totalResults={keysNumber}
        isLoading={isLoading}
        illustration="no-results"
        emptyTableTitle={t('typography.noListTitle', { listType: 'SCIM API keys' })}
        emptyTableDescription={t('typography.noListDescription', { listType: 'SCIM API keys' })}
      >
        <Table>
          <TableBody>
            {data?.map(({ id, name, secretKey, createdAt }, _index) => (
              <TableRow key={`keys-list-${id}`}>
                <TableCell>{name}</TableCell>
                <TableCell>
                  <Badge variant={'default'}>
                    <span className={styles.keyValue}>{formatSecretKey(secretKey)}</span>
                  </Badge>
                </TableCell>
                <TableCell>{format(new Date(createdAt), 'dd MMM yyyy')}</TableCell>
                <TableCell>
                  {canManageApiKeys && (
                    <ButtonText onPress={() => handleOpenModal(id, name)} data-testid="open-revoke-modal-btn">
                      {t('button.revoke')}
                    </ButtonText>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          totalResultsText={t('table.results', { count: keysNumber })}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </TableProvider>
      {selectedApiKey && (
        <RevokeScimApiKeyModal
          open={isOpenRevokeScimApiKeyModal}
          onClose={handleCloseModal}
          keyName={selectedApiKey?.name}
          keyId={selectedApiKey?.id}
          refresh={refresh}
          modalState={modalState}
          updateModalState={newState => setModalState(newState)}
        />
      )}
    </>
  )
}
