import { ProgressBar, Tooltip } from '@percent/lemonade'
import styled from 'styled-components'
import type { FundraiserProgressProps } from './fundraiser-progress.types'
import { formatMoney, formatMoneyShort } from '@percent/utility'

const StyledProgress = styled.div`
  position: relative;
  word-break: break-word;
  width: 100%;
`

export const FundraiserProgress = ({
  locale,
  donated,
  goal,
}: FundraiserProgressProps) => (
  <StyledProgress data-testid="fundraiser-progress">
    <Tooltip
      content={
        `${((donated.amount / goal.amount) * 100).toFixed(0)}% ` +
        `${formatMoney(donated, { locale })} of ${formatMoney(goal, {
          locale,
        })}`
      }
    >
      {formatMoneyShort(donated, { locale })} of{' '}
      {formatMoneyShort(goal, { locale })}
    </Tooltip>
    <ProgressBar
      progressPercentage={Math.min((donated.amount / goal.amount) * 100, 100)}
      backgroundColorComplete="info400"
      backgroundColorIncomplete="info400"
      shadeColor="gray90"
      sizeVariant="x-small"
    />
  </StyledProgress>
)
