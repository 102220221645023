import React, { useState } from 'react'
import styles from './UsersContainer.module.scss'

import { UsersTable } from './usersTable/UsersTable'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Tab, Tabs } from '@percent/lemonade'
import { useAuthState, useFeatureFlag, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { selectScopes } from '@percent/partner-dashboard/context/auth'
import { PartnerScopes } from '@percent/partner-dashboard/constants/scopes'
import { Typography } from '@material-ui/core'
import { InviteUserModal } from './modals/InviteUserModal'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import { ScimApiKeysListTable } from './scimApiKeysTable/ScimApiKeysTable'
import { CreateScimApiKeyModal } from './scimApiKeysTable/modals/CreateScimApiKeyModal'

export function UsersContainer() {
  const { t } = useTranslation()
  const { iamService, scimProvisioningService } = useServices()
  const { userRolesInviteButtonFeatureFlag } = useFeatureFlag()
  const { partner } = useAuthState()
  const scopes = selectScopes(partner?.scopes)
  const hasScimApiScope = scopes[PartnerScopes.ScimApi]
  const partnerId = partner?.id

  const { userCan } = usePermissionCheck()
  const canManageApiKeys = userCan('manageApiKeys')

  const [isOpenInviteUserModal, setIsOpenInviteUserModal] = useState(false)
  const [isOpenCreateScimApiKeyModal, setIsOpenCreateScimApiKeyModal] = useState(false)

  const [
    { data: usersData, totalResults: totalUsers, isLoading: isLoadingUsers },
    { nextPage: nextPageUsers, previousPage: previousPageUsers, refresh: refreshUsers }
  ] = useQueryList(iamService.getAccounts)

  const [
    { data: keysData, isLoading: isLoadingKeys },
    { nextPage: nextPageKeys, previousPage: previousPageKeys, refresh: refreshKeys }
  ] = useQueryList(scimProvisioningService.getScimApiKeys, { partnerId: partnerId || '' })

  type TabName = 'all' | 'scim_provisioning'
  const tabs: { label: string; key: TabName }[] = [
    {
      label: t('table.tab.allUsers'),
      key: 'all'
    },
    ...(hasScimApiScope
      ? [
          {
            label: t('table.tab.scimProvisioning'),
            key: 'scim_provisioning' as TabName
          }
        ]
      : [])
  ]

  const [activeTab, setActiveTab] = useState<TabName>('all')

  const handleRefreshScimAPIKeys = (shouldRefresh: boolean) => {
    if (shouldRefresh) {
      refreshKeys()
    }
  }

  return (
    <>
      <Flex direction="column" bg="white" p="32px" className={styles.usersContainer}>
        <Flex w="100%" justify="space-between">
          <Typography variant="h6" className={styles.tableTitle}>
            {t('menu.users')}
          </Typography>
          {userRolesInviteButtonFeatureFlag && (
            <Button
              size="small"
              startIcon="add"
              onPress={() => setIsOpenInviteUserModal(true)}
              data-testid="invite-user-button"
            >
              {t('button.invite')}
            </Button>
          )}
        </Flex>
        <Tabs
          items={tabs}
          activeTab={activeTab}
          onChangeTab={newTab => {
            setActiveTab(newTab as TabName)
          }}
        />
        <Tab data-testid="tab-users" index="all" value={activeTab}>
          <UsersTable
            data={usersData}
            totalResults={totalUsers}
            isLoading={isLoadingUsers || !usersData}
            nextPage={nextPageUsers}
            previousPage={previousPageUsers}
            refresh={refreshUsers}
          />
        </Tab>
        {hasScimApiScope && (
          <Tab data-testid="tab-scim-provisioning" index="scim_provisioning" value={activeTab}>
            <Flex direction="column" p="16px 0">
              <Flex w="100%" justify="space-between">
                <Typography variant="h6" className={styles.scimHeaderTitle}>
                  {t('table.scim.title')}
                </Typography>
                {canManageApiKeys && (
                  <Button
                    size="small"
                    onPress={() => setIsOpenCreateScimApiKeyModal(true)}
                    data-testid="create-scim-credentials-btn"
                  >
                    {t('button.createScimCredentials')}
                  </Button>
                )}
              </Flex>
              <Flex mt="16px">
                <Typography variant="body1" align="justify">
                  {t('table.scim.description')}
                </Typography>
              </Flex>
            </Flex>
            <ScimApiKeysListTable
              data={keysData}
              isLoading={isLoadingKeys || !keysData}
              refresh={() => handleRefreshScimAPIKeys(true)}
              nextPage={nextPageKeys}
              previousPage={previousPageKeys}
              canManageApiKeys={canManageApiKeys}
            />
          </Tab>
        )}
      </Flex>
      <InviteUserModal
        openModal={isOpenInviteUserModal}
        onClose={() => {
          setIsOpenInviteUserModal(false)
        }}
      />
      <CreateScimApiKeyModal
        open={isOpenCreateScimApiKeyModal}
        onClose={() => {
          setIsOpenCreateScimApiKeyModal(false)
        }}
        refresh={() => handleRefreshScimAPIKeys(true)}
      />
    </>
  )
}
