import { ReactNode } from 'react'

export enum PartnerConfigKey {
  SUPPORTED_CURRENCIES = 'supported_currencies',
  FEATURED = 'featured',
  GAMIFICATION_DISPLAY = 'gamification_display',
  PREFERRED_COUNTRIES = 'preferred_countries'
}

export type PartnerConfigContextType = {
  configData: { partnerId: string; key: string; value: string[]; createdAt: string } | null
  isConfigError: { code: string; message: string; reasons: string[]; title: string } | null
}

export type PartnerConfigProviderProps = {
  children: ReactNode
  partnerConfigKey: PartnerConfigKey
}
