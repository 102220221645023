export const apiPaths = {
  v1Partner: 'v1/partner/',
  v1Admin: 'v1/admin/',
  v1Auth: 'v1/auth/',
  v1Account: 'v1/account/',
  v1Category: '/v1/categories',
  v1Countries: 'v1/countries/',
  v1Nonprofits: '/v1/organisations',
  v1Users: '/v1/users',
  v1IamAccounts: '/v1/iam/accounts',
  v1IamAccountsRolesAttach: '/iam/accounts/roles/attach',
  v1IamPermissions: '/v1/iam/permissions',
  v1IamRoles: '/v1/iam/roles',
  v1IamScim: '/v1/scim/api-keys'
}

export const baseUrl = 'v1/'
