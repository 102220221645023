import { AxiosInstance } from 'axios'
import { PBPListResponse, PBPResponse } from '../types/pbpResponses.types'
import { apiPaths } from '../apiPaths'
import { GetScimApiKeysProps, ScimApiKey } from './scimProvisioningService.types'

type scimProvisioningServiceCapabilities = {
  percentClient: AxiosInstance
}

export const scimProvisioningService = ({ percentClient }: scimProvisioningServiceCapabilities) => ({
  getScimApiKeys: (params: GetScimApiKeysProps) =>
    percentClient.get<PBPListResponse<ScimApiKey>>(apiPaths.v1IamScim, { params }),
  postScimApiKey: (name: string) => {
    return percentClient.post<PBPResponse<ScimApiKey>>(apiPaths.v1IamScim, { name })
  },
  revokeScimApiKey: (id: string) => {
    return percentClient.delete<PBPResponse<ScimApiKey>>(`${apiPaths.v1IamScim}/${id}`)
  }
})
