import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useAuthState, useDidMountEffect, useQuery } from '@percent/partner-dashboard/common/hooks'
import { CountriesContext } from './CountriesContext'

import { CountriesContextControllerProps } from './CountriesContextController.types'
import { Alpha3Code } from 'i18n-iso-countries'
import { useCallback, useMemo } from 'react'

export const CountriesContextController = ({ children }: CountriesContextControllerProps) => {
  const { countryService } = useServices()
  const { isAuthorised } = useAuthState()
  const [{ data }, { query }] = useQuery(countryService.getCountries, {}, isAuthorised)

  const getCountryNameByCode = useCallback(
    (countryCode: Alpha3Code) => data?.find(({ code }) => code === countryCode)?.name || '',
    [data]
  )

  useDidMountEffect(() => isAuthorised && query({}), [query, isAuthorised])

  const value = useMemo(() => ({ countries: data, getCountryNameByCode }), [data, getCountryNameByCode])

  return <CountriesContext.Provider value={value}>{children}</CountriesContext.Provider>
}
