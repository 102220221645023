export const mockGrantProgramsData = [
  {
    id: '1',
    name: '2025 Community Fund',
    opens_at: '2025-03-01T00:00:00Z',
    closes_at: '2025-04-15T00:00:00Z'
  },
  {
    id: '2',
    name: 'Airbnb.org Housing Grant - Hurricane Milton',
    opens_at: '2024-03-01T00:00:00Z',
    closes_at: '2024-07-15T00:00:00Z'
  }
]
