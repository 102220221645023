import { createContext } from 'react'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { Loader } from '@percent/lemonade'
import { useQuery } from '@percent/partner-dashboard/common/hooks'
import { PartnerConfigContextType, PartnerConfigProviderProps } from './PartnerConfigProvider.types'

export const PartnerConfigContext = createContext<PartnerConfigContextType | undefined>(undefined)

export const PartnerConfigProvider = ({ children, partnerConfigKey }: PartnerConfigProviderProps) => {
  const { partnerService } = useServices()
  const [{ data, isLoading, error }] = useQuery(partnerService.getConfig, partnerConfigKey)

  if (isLoading) {
    return <Loader />
  }

  return (
    <PartnerConfigContext.Provider value={{ configData: data, isConfigError: error }}>
      {children}
    </PartnerConfigContext.Provider>
  )
}
