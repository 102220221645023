import { Flex } from '@percent/lemonade'
import styles from './GrantProgramApplicationsContainer.module.scss'

export function GrantProgramApplicationsContainer() {
  return (
    <Flex h="70vh" bg="white" className={styles.grantProgramNominationsContainer}>
      <Flex p="50px"> Applications table here </Flex>
    </Flex>
  )
}
