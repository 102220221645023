import React, { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { object, string } from 'yup'
import { Button, FormField, Spacer, Select, Grid } from '@percent/lemonade'
import { useMutation, useQuery } from '@percent/partner-dashboard/common/hooks'
import { Modal } from '@percent/partner-dashboard/common/components/modal/Modal'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { EditUserModalProps } from './EditUserModal.types'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import { useTranslation } from 'react-i18next'

export const EditUserModal = ({ openModalData, onClose, refresh }: EditUserModalProps) => {
  const { t } = useTranslation()
  const { iamService } = useServices()
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([])
  const [selectedValue, setSelectedValue] = useState<SelectOption>({
    label: '',
    value: '',
    description: ''
  })

  const [{ isLoading: isSaving }, { apiFunc: putRolesAttach }] = useMutation(iamService.patchRoles, () => refresh())
  const [{ body, isLoading }] = useQuery(iamService.getRoles, {})

  useEffect(() => {
    if (body) {
      const newOptinsList: SelectOption[] = []

      body?.data?.roles.map(role => {
        if (role.displayName === openModalData?.role) {
          setSelectedValue({
            label: role.displayName,
            value: role.id,
            description: role.description
          })
        }
        newOptinsList.push({
          label: role.displayName,
          value: role.id,
          description: role.description
        })
      })

      setSelectOptions(newOptinsList)
    }
  }, [body, openModalData?.role])

  const formik = useFormik({
    initialValues: { role: selectedValue.value },
    validationSchema: () =>
      object().shape({
        role: string()
      }),
    onSubmit: () => {
      if (!openModalData) return
      putRolesAttach({
        accountId: openModalData.userId,
        roleId: selectedValue.value
      })
      onClose()
    }
  })

  const { errors, values, touched, handleSubmit, setValues } = formik

  return (
    <Modal title={t('dialog.editUser.title')} openModal={Boolean(openModalData)} onClose={onClose}>
      <Spacer size={5} axis="vertical" />
      <span style={{ fontWeight: 500 }}>{openModalData?.email}</span>{' '}
      <span>{t('typography.editUser.role', { role: openModalData?.role })}</span>
      <Spacer size={6} axis="vertical" />
      <form onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <FormField
            label={t('typography.role')}
            status={touched.role && errors.role ? 'danger' : 'default'}
            statusMessage={errors.role}
          >
            <Select
              name="role"
              placeholder={t('form.selectRole')}
              onChange={e => {
                setValues({ ...values, role: e.value })
                setSelectedValue({
                  label: e.label,
                  value: e.value,
                  description: e.description
                })
              }}
              options={selectOptions}
              defaultValue={selectedValue}
            />
          </FormField>
          <Spacer size={10} axis="vertical" />
          <Grid justify="flex-end">
            <Button type="submit" size="large" loading={isLoading || isSaving} data-testid="save">
              {t('button.saveChanges')}
            </Button>
          </Grid>
        </FormikProvider>
      </form>
    </Modal>
  )
}
