import React from 'react'
import { Grid } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import { number, object, string } from 'yup'
import { Button, FormField, Select, Spacer, TextInput } from '@percent/lemonade'
import { useQuery } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { Modal } from '@percent/partner-dashboard/common/components/modal/Modal'
import { useTranslation } from 'react-i18next'
import { emailRegex } from '@percent/utility'

export const InviteUserModal = ({ openModal, onClose }) => {
  const { t } = useTranslation()
  const { iamService } = useServices()

  const [{ isLoading }] = useQuery(iamService.getRoles, {})

  const formik = useFormik({
    initialValues: {
      email: '',
      role: 'staging_role_000000CScDbbbfiIPQyB2ZK1mqbFA'
    },
    validationSchema: () =>
      object().shape({
        email: string().required('Required').matches(emailRegex),
        role: number().required('Required')
      }),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {}
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  return (
    <Modal title={t('dialog.inviteUser.title')} openModal={openModal} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Spacer size={6} axis="vertical" />
        <FormikProvider value={formik}>
          <FormField
            label={t('form.emailAddress')}
            status={touched.email && errors.email ? 'danger' : 'default'}
            statusMessage={errors.email}
            data-testid="email"
          >
            <TextInput
              name="email"
              placeholder={t('form.enterEmailAddress')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
          </FormField>
          <Spacer size={4} axis="vertical" />
          <FormField
            label={t('typography.role')}
            status={touched.role && errors.role ? 'danger' : 'default'}
            statusMessage={errors.role}
            data-testid="role"
            description={t('form.selectUserRole')}
          >
            <Select
              name="role"
              placeholder={t('form.selectRole')}
              onChange={handleChange}
              options={[
                { label: 'Editor', value: 'editor' },
                { label: 'Viewer', value: 'viewer' }
              ]}
            />
          </FormField>
        </FormikProvider>
        <Spacer size={10} axis="vertical" />
        <Grid container justifyContent="flex-end">
          <Button type="submit" size="large" loading={isLoading} onPress={onClose}>
            {t('button.sendInvite')}
          </Button>
        </Grid>
      </form>
    </Modal>
  )
}
