import React, { useState } from 'react'
import {
  Badge,
  Table,
  TableRow,
  TableCell,
  TablePagination,
  Tooltip,
  TableProvider,
  Loader,
  TableBody
} from '@percent/lemonade'
import { useAuthState } from '@percent/partner-dashboard/common/hooks'
import { RemoveUserModal } from './modals/RemoveUserModal'
import { EditUserModal } from './modals/EditUserModal'
import styles from './UsersTable.module.scss'
import { EditUserModalProps } from './modals/EditUserModal.types'
import { RemoveUserModalProps } from './modals/RemoveUserModal.types'
import { usePermissionCheck } from '@percent/partner-dashboard/common/hooks/useAllow/usePermissionCheck'
import { DefaultRoleId } from '@percent/partner-dashboard/context/iam/abilityDefinitions'
import { useTranslation } from 'react-i18next'
import { UsersTableProps } from './UsersTable.types'
import { UsersActionsMenu } from './usersActionMenu/UsersActionsMenu'

export function UsersTable({
  data,
  isLoading,
  totalResults,
  previousPage,
  nextPage,
  refresh
}: Readonly<UsersTableProps>) {
  const { t } = useTranslation()
  const { user: currentUser } = useAuthState()
  const { userCan } = usePermissionCheck()
  const [editModalData, setEditModalData] = useState<EditUserModalProps['openModalData']>(undefined)
  const [removeModalData, setRemoveModalData] = useState<RemoveUserModalProps['openModalData']>(undefined)

  const isEditable = (idOfEditedUser: string, roleIdOfEditedUser: DefaultRoleId) => {
    if (!currentUser || currentUser.id === idOfEditedUser) {
      return false
    }

    switch (roleIdOfEditedUser) {
      case 'pd:owner':
        return userCan('manageOwners')
      case 'pd:admin':
        return userCan('manageAdmins')
      case 'pd:developer':
        return userCan('manageDevelopers')
      case 'pd:member':
        return userCan('manageMembers')
      default:
        // For unknown roles, we allow admins to edit.
        return userCan('manageAdmins')
    }
  }

  if (isLoading) {
    return <Loader />
  }

  const columnsUsers = [
    { id: t('table.header.email'), isSortable: false },
    { id: t('table.header.role'), isSortable: false },
    { id: t('table.header.2fa'), isSortable: false },
    { id: t('table.header.actions'), isSortable: false }
  ]

  const mapTooltip = (name: string) => {
    const tooltip = {
      Owner: t('toolTip.myTeam.owner'),
      Admin: t('toolTip.myTeam.admin'),
      Developer: t('toolTip.myTeam.developer'),
      Member: t('toolTip.myTeam.member')
    }
    return tooltip[name]
  }

  return (
    <TableProvider
      columns={columnsUsers}
      totalResults={totalResults || 0}
      isLoading={isLoading}
      illustration="no-results"
      emptyTableTitle={t('typography.noListTitle', { listType: 'users' })}
      emptyTableDescription={t('typography.noListDescription', { listType: 'users' })}
    >
      <>
        <Table data-testid="users-table">
          <TableBody>
            {data?.map(({ id, email, role, mfaEnabled, status }, _index) => (
              <TableRow key={`permissions-list-${id}`}>
                <TableCell>
                  <div className={styles.cellWrapper}>
                    <span className={status === 'PENDING' ? styles.disabled : undefined}>{email}</span>
                    {status === 'PENDING' && (
                      <Badge variant="default" icon="clock">
                        {t('table.pending')}
                      </Badge>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <Tooltip content={mapTooltip(role.name)}>
                    {''}
                    <Badge variant="default" testId="role-badge">
                      {role.name}
                    </Badge>
                    {''}
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {mfaEnabled ? (
                    <Badge icon="check-badge" variant="positive">
                      {t('2fa.enable')}
                    </Badge>
                  ) : (
                    <Badge icon="warning" variant="critical">
                      {t('2fa.disable')}
                    </Badge>
                  )}
                </TableCell>
                <TableCell className={styles.selectCell} data-testid="actions-table-cell">
                  {isEditable(id, role.id) && (
                    <UsersActionsMenu
                      items={[{ key: 'edit', label: t('button.edit') }]}
                      handleSelect={(actionKey: string) => {
                        if (actionKey === 'edit') {
                          setEditModalData({ userId: id, email, role: role.name })
                        }
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          totalResultsText={t('table.results', { count: totalResults || undefined })}
          previousPage={previousPage}
          nextPage={nextPage}
        />
        <EditUserModal
          refresh={refresh}
          openModalData={editModalData}
          onClose={() => {
            setEditModalData(undefined)
          }}
        />
        <RemoveUserModal
          refresh={refresh}
          openModalData={removeModalData}
          onClose={() => {
            setRemoveModalData(undefined)
          }}
        />
      </>
    </TableProvider>
  )
}
