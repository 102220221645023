import {
  Modal,
  Alert,
  FormField,
  TextInput,
  Spacer,
  Copy,
  Label,
  Flex,
  Text,
  AlertVariant,
  ActionModal,
  Tooltip
} from '@percent/lemonade'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'
import styles from './CreateScimApiKeyModal.module.scss'
import { CheckIcon, UnstyledButton } from '@mantine/core'

interface CreateScimAPIKeyModalProps {
  open: boolean
  onClose: (refresh: boolean) => void
  refresh: () => void
}

enum ModalState {
  CREATE_KEY_FORM = 'Create key',
  COPY_KEY_FORM = 'Copy key'
}

export const CreateScimApiKeyModal = ({ open, onClose, refresh }: CreateScimAPIKeyModalProps) => {
  const { t } = useTranslation()
  const { scimProvisioningService } = useServices()
  const [modalState, setModalState] = useState(ModalState.CREATE_KEY_FORM)
  const [alertVariant, setAlertVariant] = useState<{ variant: AlertVariant; text: string; primaryBtn: string }>({
    variant: 'warning',
    text: t('modal.createScimApiKey.warningAlert'),
    primaryBtn: t('button.keyCopied')
  })
  const [{ data, error, isLoading: isMutating }, { apiFunc: createKeyAPIRequest }] = useMutation(
    scimProvisioningService.postScimApiKey,
    () => {
      refresh()
      setModalState(ModalState.COPY_KEY_FORM)
    },
    () => {
      setAlertVariant({
        variant: 'error',
        text: t('modal.createScimApiKey.errorAlert'),
        primaryBtn: t('button.close')
      })
      setModalState(ModalState.COPY_KEY_FORM)
    }
  )

  const secretKey = data?.data?.data.secretKey

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    onSubmit: values => {
      createKeyAPIRequest(values.name)
    },
    validationSchema: () =>
      object().shape({
        name: string()
          .required(t('errorMessage.required'))
          .min(8, t('errorMessage.minLength', { length: 8 }))
          .max(32, t('errorMessage.maxLength', { length: 32 }))
      })
  })

  const { touched, errors, handleChange, handleSubmit, handleBlur, setFieldValue, values, resetForm } = formik

  useEffect(() => {
    if (values.name) {
      setFieldValue('name', values.name)
    }
  }, [setFieldValue, values.name])

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  const handleCloseCopyKey = () => {
    setModalState(ModalState.CREATE_KEY_FORM)
    onClose(false)
    setAlertVariant({
      variant: 'warning',
      text: t('modal.createScimApiKey.warningAlert'),
      primaryBtn: t('button.keyCopied')
    })
  }

  const [clipboardIsCopied, setClipboardIsCopied] = useState(false)
  const handleCopyKey = () => {
    navigator.clipboard.writeText(secretKey || '')
    setClipboardIsCopied(true)
  }

  useEffect(() => {
    if (clipboardIsCopied) {
      setTimeout(() => {
        setClipboardIsCopied(false)
      }, 1700)
    }
  }, [clipboardIsCopied])

  return (
    <Modal open={open} onClose={() => onClose(false)} data-testid="create-api-key-modal">
      {modalState === ModalState.CREATE_KEY_FORM && (
        <ActionModal
          title={t('modal.createScimApiKey.title')}
          loading={isMutating}
          type="submit"
          disabled={!formik.isValid || !formik.dirty}
          primaryButtonText={t('button.next')}
          primaryBtnTestId="create-key-btn-next"
          secondaryButtonText={t('button.cancel')}
          secondaryBtnTestId="cancel-btn"
          handleClose={() => onClose(false)}
          handleSubmit={handleSubmit}
        >
          <form onSubmit={handleSubmit}>
            <FormField
              label={t('form.tokenName')}
              status={touched.name && errors.name ? 'danger' : 'default'}
              statusMessage={errors.name}
              data-testid="key-name-input-field"
              necessity="required"
            >
              <TextInput
                name="key-name"
                placeholder={t('form.tokenName.placeholder')}
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue('name', e.target.value)
                  handleChange(e)
                }}
                data-testid={'key-name-input'}
                value={values.name}
              />
            </FormField>
          </form>
        </ActionModal>
      )}
      {modalState === ModalState.COPY_KEY_FORM && (
        <ActionModal
          title={t('modal.createScimApiKey.title')}
          primaryButtonText={alertVariant.primaryBtn}
          primaryBtnTestId="copy-key-primary-btn"
          handleClose={handleCloseCopyKey}
          handleSubmit={handleCloseCopyKey}
        >
          <Alert variant={alertVariant.variant}> {alertVariant.text} </Alert>
          <Spacer axis="vertical" size={4} />
          {secretKey && !error && (
            <>
              <Label htmlFor={undefined} children={t('form.tokenValue')} />
              <Spacer axis="vertical" size={2} />
              <Flex className={styles.keyValue} data-testid="key-value">
                {clipboardIsCopied && (
                  <Flex className={styles.copyHint} data-testid="copy-hint">
                    {' '}
                    <CheckIcon size={14} color="white" /> {t('form.tokenValue.copied')}
                  </Flex>
                )}
                <Text> {secretKey} </Text>
                <UnstyledButton onClick={handleCopyKey} data-testid="copy-key-btn">
                  <Copy text={secretKey || 'text'} withIcon />
                </UnstyledButton>
              </Flex>
            </>
          )}
        </ActionModal>
      )}
    </Modal>
  )
}
