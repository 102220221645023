import { Trans, useTranslation } from 'react-i18next'
import { useState } from 'react'

import { AcknowledgeModal, ActionModal, Modal } from '@percent/lemonade'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { FundraiserModalProps } from '../FundraiserModalProps.types'

export function RemoveFeaturedFundraiserModal({
  isOpen,
  onClose,
  refresh,
  fundraiser
}: Readonly<FundraiserModalProps>) {
  const { t } = useTranslation()
  const { workplaceGivingService } = useServices()
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const [{ isLoading }, { apiFunc: closeFundraiser }] = useMutation(
    workplaceGivingService.removeFeaturedItem,
    () => {
      setIsSuccess(true)
      refresh()
    },
    () => {
      setIsError(true)
    }
  )

  const handleRemoveFeaturedFundraiser = () => {
    closeFundraiser(fundraiser.featuredItemId!)
  }

  const handleCloseSuccessModal = () => {
    setIsSuccess(false)
    onClose()
  }

  const handleCloseErrorModal = () => {
    setIsError(false)
    onClose()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <>
      {!isSuccess && !isError && (
        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="open-remove-featured-fundraiser"
          data-testid="open-remove-featured-fundraiser"
        >
          <ActionModal
            title={t('dialog.fundraisers.remove.title')}
            primaryButtonText={t('button.remove')}
            secondaryButtonText={t('button.cancel')}
            handleClose={handleClose}
            handleSubmit={handleRemoveFeaturedFundraiser}
            loading={!!isLoading}
            primaryBtnTestId="btn-remove-featured-fundraiser"
            disabled={!!isLoading}
          >
            <Trans
              i18nKey="dialog.fundraisers.remove.description"
              components={{ b: <b /> }}
              values={{ fundraiserTitle: fundraiser.title }}
            />
          </ActionModal>
        </Modal>
      )}
      <Modal
        open={isSuccess}
        onClose={handleCloseSuccessModal}
        aria-labelledby="open-remove-featured-fundraiser-success"
        data-testid="open-remove-featured-fundraiser-success"
      >
        <AcknowledgeModal
          result="positive"
          title={t('dialog.fundraisers.remove.success.title')}
          description={
            <Trans
              i18nKey="dialog.fundraisers.remove.success.description"
              components={{ b: <b /> }}
              values={{ fundraiserTitle: fundraiser.title }}
            />
          }
          buttonText={t('button.close')}
          handleClose={handleCloseSuccessModal}
          buttonTestId="success-modal-btn"
        />
      </Modal>
      <Modal
        open={isError}
        onClose={handleCloseErrorModal}
        aria-labelledby="open-remove-featured-fundraiser-error"
        data-testid="open-remove-featured-fundraiser-error"
      >
        <AcknowledgeModal
          result="negative"
          title={t('dialog.fundraisers.remove.error.title')}
          description={t('dialog.error.description')}
          buttonText={t('button.close')}
          handleClose={handleCloseErrorModal}
          buttonTestId="error-modal-btn"
        />
      </Modal>
    </>
  )
}
