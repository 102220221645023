import { useState } from 'react'
import { FeaturedFundraiser } from '../FundraisersModal/FundraiserModalProps.types'

export function useFeatureFundraiserActionModal() {
  const [selectedFeaturedFundraiser, setSelectedFeaturedFundraiser] = useState<FeaturedFundraiser | undefined>(
    undefined
  )

  const [isFeaturedFundraiserModalOpen, setIsFeaturedFundraiserModalOpen] = useState<boolean>(false)
  const [isRemoveFeaturedFundraiserModalOpen, setIsRemoveFeaturedFundraiserModalOpen] = useState<boolean>(false)

  const openFeaturedFundraiserModal = ({ id, title }: { id: string; title: string }) => {
    setSelectedFeaturedFundraiser({ id, title })
    setIsFeaturedFundraiserModalOpen(true)
  }

  const closeFeaturedFundraiserModal = () => {
    setIsFeaturedFundraiserModalOpen(false)
  }

  const openRemoveFeaturedFundraiserModal = ({ featuredItemId, title }: { featuredItemId: string; title: string }) => {
    setSelectedFeaturedFundraiser({ featuredItemId, title })
    setIsRemoveFeaturedFundraiserModalOpen(true)
  }

  const closeRemoveFeaturedFundraiserModal = () => {
    setIsRemoveFeaturedFundraiserModalOpen(false)
  }

  return {
    selectedFeaturedFundraiser,
    isFeaturedFundraiserModalOpen,
    openFeaturedFundraiserModal,
    closeFeaturedFundraiserModal,
    isRemoveFeaturedFundraiserModalOpen,
    openRemoveFeaturedFundraiserModal,
    closeRemoveFeaturedFundraiserModal
  }
}
