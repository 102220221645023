import { useContext } from 'react'
import { PartnerConfigContext } from '@percent/partner-dashboard/context/config/PartnerConfigProvider'
import { PartnerConfigContextType } from '@percent/partner-dashboard/context/config/PartnerConfigProvider.types'

export const usePartnerConfig = (): PartnerConfigContextType => {
  const context = useContext(PartnerConfigContext)
  if (!context) {
    throw new Error('usePartnerConfig must be used within a PartnerConfigProvider')
  }
  return context
}
