import { AxiosInstance } from 'axios'

import { apiPaths } from '@percent/partner-dashboard/services/apiPaths'

type PartnerServiceCapabilities = {
  percentClient: AxiosInstance
}

export const partnerService = ({ percentClient }: PartnerServiceCapabilities) => ({
  get: () => percentClient.get(`${apiPaths.v1Auth}/partners`),
  getConfig: key => percentClient.get(`${apiPaths.v1Partner}giving/config/${key}`)
})
