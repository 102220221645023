import { AcknowledgeModal, AcknowledgeModalProps, ConfirmationModal, Modal } from '@percent/lemonade'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface RevokeScimApiKeyModalProps {
  keyName: string
  keyId: string
  open: boolean
  onClose: (refresh: boolean) => void
  refresh: () => void
  modalState: RevokeKeyModalState
  updateModalState: (state: RevokeKeyModalState) => void
}

enum RevokeKeyModalState {
  OPERATION_CONFIRMATION = 'Revoke key confirmation',
  STATUS = 'Operation status'
}

export const RevokeScimApiKeyModal = ({
  keyName,
  keyId,
  open,
  onClose,
  refresh,
  modalState,
  updateModalState
}: RevokeScimApiKeyModalProps) => {
  const { t } = useTranslation()
  const { scimProvisioningService } = useServices()
  const [acknowledgeModalData, setAcknowledgeModalData] = useState<AcknowledgeModalProps>({
    title: '',
    description: '',
    result: 'positive'
  })

  const [{ isLoading: isRevoking }, { apiFunc: revokeKey }] = useMutation(
    scimProvisioningService.revokeScimApiKey,
    () => {
      setAcknowledgeModalData({
        title: t('modal.revokeScimApiKey.success.title'),
        description: '',
        result: 'positive'
      })
      updateModalState(RevokeKeyModalState.STATUS)
      refresh()
    },
    () => {
      setAcknowledgeModalData({
        title: t('modal.revokeScimApiKey.failure.title'),
        description: '',
        result: 'negative'
      })
      updateModalState(RevokeKeyModalState.STATUS)
    }
  )

  const handleSubmit = () => {
    revokeKey(keyId)
  }

  return (
    <Modal open={open} onClose={() => onClose(false)} data-testid="revoke-api-key-modal">
      {modalState === RevokeKeyModalState.OPERATION_CONFIRMATION && (
        <ConfirmationModal
          title={t('modal.revokeScimApiKey.title')}
          type="submit"
          loading={isRevoking}
          primaryButtonText={t('button.revoke')}
          primaryBtnTestId="revoke-key-btn"
          secondaryButtonText={t('button.cancel')}
          secondaryBtnTestId="cancel-btn"
          handleClose={() => onClose(false)}
          handleSubmit={handleSubmit}
        >
          <Trans i18nKey="modal.revokeScimApiKey.description" values={{ keyName }} components={{ b: <strong /> }} />
        </ConfirmationModal>
      )}
      {modalState === RevokeKeyModalState.STATUS && (
        <AcknowledgeModal
          title={acknowledgeModalData.title}
          handleClose={() => {
            updateModalState(RevokeKeyModalState.OPERATION_CONFIRMATION)
            onClose(acknowledgeModalData.result === 'positive')
          }}
          description={acknowledgeModalData.description}
          result={acknowledgeModalData.result as 'positive' | 'negative'}
          buttonText={t('button.close')}
          buttonTestId="close-acknowledge-revoke-key-modal-btn"
          viewTestId="revoke-key-acknowledge-modal-view"
        />
      )}
    </Modal>
  )
}
