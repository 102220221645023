import { Trans, useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { AcknowledgeModal, ActionModal, FormField, Modal, Spacer, Text } from '@percent/lemonade'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { useState } from 'react'
import { useFormik } from 'formik'
import { Kind } from '@percent/partner-dashboard/services/workplaceGiving/workplaceGivingService.types'
import { useCountryData } from '@percent/partner-dashboard/common/hooks/useCountryData/useCountryData'
import { getCountryNamesFromAlpha3Codes } from '@percent/partner-dashboard/i18n/countries'
import {
  formatCountryNamesString,
  mapCountriesToOptions
} from '@percent/partner-dashboard/common/library/utility/utility'
import { MultiSelect } from '@percent/partner-dashboard/common/components'
import { FeatureOpportunityModalProps } from './FeatureOpportunityModalProps.types'
import { usePartnerConfig } from '@percent/partner-dashboard/common/hooks/usePartnerConfig/usePartnerConfig'

export function FeatureOpportunityModal({
  isOpen,
  onClose,
  refresh,
  opportunity
}: Readonly<FeatureOpportunityModalProps>) {
  const { t } = useTranslation()
  const { workplaceGivingService } = useServices()
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const { countries } = useCountryData()
  const { configData } = usePartnerConfig()

  const [{ isLoading, data }, { apiFunc: featureOpportunityFunc }] = useMutation(
    workplaceGivingService.featureItem,
    () => {
      setIsSuccess(true)
    },
    () => {
      setIsError(true)
    }
  )

  const handleCloseSuccessModal = () => {
    setIsSuccess(false)
    refresh()
    onClose()
  }

  const handleCloseErrorModal = () => {
    setIsError(false)
    onClose()
  }

  const handleClose = () => {
    onClose()
  }

  const countryOptions = mapCountriesToOptions(countries, configData?.value)

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      country: []
    },
    validationSchema: Yup.object().shape({
      country: Yup.array().of(Yup.string())
    }),
    onSubmit: () => featureOpportunityFunc({ country: values.country, kind: Kind.Opportunity, itemId: opportunity.id! })
  })

  return (
    <>
      {!isSuccess && !isError && (
        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="open-feature-opportunity"
          data-testid="open-feature-opportunity"
        >
          <ActionModal
            title={t('dialog.volunteering.feature.title')}
            primaryButtonText={t('button.confirm')}
            secondaryButtonText={t('button.cancel')}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            loading={!!isLoading}
            primaryBtnTestId="btn-feature-opportunity"
            disabled={!!isLoading}
          >
            <Text size="small">
              <Trans
                i18nKey="dialog.volunteering.feature.description"
                values={{ opportunityName: opportunity.name }}
                components={{ b: <b /> }}
              />
            </Text>
            <Spacer size={4} axis="vertical" />
            <FormField
              label={t('dialog.volunteering.feature.select.label')}
              necessity="optional"
              data-testid="country-select"
              description={t('dialog.volunteering.feature.select.description')}
            >
              <MultiSelect
                value={values.country}
                setFieldValue={setFieldValue}
                options={countryOptions}
                onChangeFieldName="country"
                placeholder={t('dialog.volunteering.feature.select.placeholder')}
              />
            </FormField>
          </ActionModal>
        </Modal>
      )}
      {data && (
        <Modal
          open={isSuccess}
          onClose={handleCloseSuccessModal}
          aria-labelledby="feature-opportunity-success"
          data-testid="feature-opportunity-success"
        >
          <AcknowledgeModal
            result="positive"
            title={t('dialog.volunteering.feature.success.title')}
            description={
              data?.country.length > 0 ? (
                <Trans
                  i18nKey="dialog.volunteering.feature.success.countrySpecific"
                  values={{
                    opportunityName: opportunity.name,
                    countries: formatCountryNamesString(getCountryNamesFromAlpha3Codes(data?.country))
                  }}
                  components={{ b: <b /> }}
                />
              ) : (
                <Trans
                  i18nKey="dialog.volunteering.feature.success.global"
                  values={{ opportunityName: opportunity.name }}
                  components={{ b: <b /> }}
                />
              )
            }
            buttonText={t('button.close')}
            handleClose={handleCloseSuccessModal}
            buttonTestId="success-modal-btn"
          />
        </Modal>
      )}
      <Modal
        open={isError}
        onClose={handleCloseErrorModal}
        aria-labelledby="feature-opportunity-error"
        data-testid="feature-opportunity-error"
      >
        <AcknowledgeModal
          result="negative"
          title={t('dialog.volunteering.feature.error.title')}
          description={t('dialog.error.description')}
          buttonText={t('button.close')}
          handleClose={handleCloseErrorModal}
          buttonTestId="error-modal-btn"
        />
      </Modal>
    </>
  )
}
